<template>
    <div class="center">
        <div class="heads">
            <div style="width: 1240px;margin: 0 auto;">
                <router-link to="/">

                    <img src="../images/logo.png" alt="" style="width: 138px;height: 105px;cursor: pointer;">
                </router-link>
              
            </div>
        </div>
        <div class="barr1">
            <img src="../images/enter1.png" class="imgs" alt="">
            <div class="cent">
                <div class="titl">企业名称</div>
                <div class="inp flexal">
                    <input type="text" placeholder="请填写企业名称" style="width: 100%;" v-model="name">
                </div>
                <div class="titl">所在地</div>
                <!-- <div class="inp flexal"> -->
                <!-- <input type="text" placeholder="请选择所在地" style="width: 100%;"> -->
                <el-cascader class="inps" size="large" :options="options" v-model="selectedOptions" @change="handleChange">
                </el-cascader>
                <!-- </div> -->
                <div class="titl">所售商品</div>
                <div class="inp flexal">
                    <input type="text" placeholder="请列举1-4个主营商品，逗号分隔" style="width: 100%;" v-model="goods">
                </div>
                <div class="titl">手机号</div>
                <div class="inp flexal">
                    <input type="text" placeholder="请请输入手机号" style="width: 100%;" v-model="phone">
                </div>
                <div class="flexcen but" @click="put">
                    立即入驻
                </div>
                <div class="tex flexcen">
                    提交联系方式会安排服务商和您尽快联系
                </div>
            </div>
        </div>
        <div class="yaoqiu">
            <div class="titls">
                入驻要求
                <div class="heng"></div>
            </div>
            <div class="yaoqiu2 flexcen">
                <img src="./images/yaoqiu2.png" alt="" class="imgs2">
                入驻基本要求
            </div>
            <div v-html="config.ruzhuyaoqiu" style="margin: 32px 0;"></div>
            <div class="yaoqiu2 flexcen">
                <img src="./images/yaoqiu1.png" alt="" class="imgs2">
                权益特殊要求
            </div>
            <div v-html="config.quanyiyaoqiu" style="margin: 32px 0;"></div>
            <div class="titls">
                入驻流程
                <div class="heng"></div>
            </div>
            <div class="liucheng flexcen">
                <div class="yuan flexcen flexcum">
                    <img src="./images/enter1.png" class="yuanImg" alt="">
                    入驻成为会员
                </div>
                <div class="heng2"></div>
                <div class="yuan flexcen flexcum">
                    <img src="./images/enter2.png" class="yuanImg" alt="">
                    确认合作方案
                    完成签约
                </div>
                <div class="heng2"></div>
                <div class="yuan flexcen flexcum">
                    <img src="./images/enter3.png" class="yuanImg" alt="">
                    进行资质认证
                </div>
                <div class="heng2"></div>
                <div class="yuan flexcen flexcum">
                    <img src="./images/enter4.png" class="yuanImg" alt="">
                    完成实力供应商
                    会员入驻
                </div>
            </div>
        </div>
        <div class="barr1" v-if="false">
            <img src="../images/enter2.png" class="imgs" alt="">
            <div class="cent" style="background: rgba(0, 0, 0, .6);">
                <div class="titl" style="color: #fff;">企业名称</div>
                <div class="inp flexal">
                    <input type="text" placeholder="请填写企业名称" style="width: 100%;" v-model="name">
                </div>
                <div class="titl" style="color: #fff;">所在地</div>
                <!-- <div class="inp flexal"> -->
                <!-- <input type="text" placeholder="请选择所在地" style="width: 100%;"> -->
                <el-cascader class="inps" size="large" :options="options" v-model="selectedOptions" @change="handleChange">
                </el-cascader>
                <!-- </div> -->
                <div class="titl" style="color: #fff;">所售商品</div>
                <div class="inp flexal">
                    <input type="text" placeholder="请列举1-4个主营商品，逗号分隔" style="width: 100%;" v-model="goods">
                </div>
                <div class="titl" style="color: #fff;">手机号</div>
                <div class="inp flexal">
                    <input type="text" placeholder="请请输入手机号" style="width: 100%;" v-model="phone">
                </div>
                <div class="flexcen but" @click="put">
                    立即入驻
                </div>
                <div class="tex flexcen" style="color: #fff;">
                    提交联系方式会安排服务商和您尽快联系
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { provinceAndCityData, pcTextArr, regionData, pcaTextArr, CodeToText } from 'element-china-area-data'
export default {
    data() {
        return {
            options: regionData,
            selectedOptions: [],
            area: '',
            name: '',
            goods: '',
            phone: '',
            config:''
        };
    },

    activated() {

        this.getData()
    },
    methods: {
        async getData() {
            let data = await this.postdata("/News/GetConfig", {
                "queryType":1
            })
            this.config = data.data
        },
        handleChange(value) {
            console.log(value)
            this.getCodeToText(null, value)
        },
        getCodeToText(codeStr, codeArray) {
            if (null === codeStr && null === codeArray) {
                return null;
            } else if (null === codeArray) {
                codeArray = codeStr.split(",");
            }
            let area = "";
            switch (codeArray.length) {
                case 1:
                    area += CodeToText[codeArray[0]];
                    break;
                case 2:
                    area += CodeToText[codeArray[0]] + "/" + CodeToText[codeArray[1]];
                    break;
                case 3:
                    area +=
                        CodeToText[codeArray[0]] +
                        "/" +
                        CodeToText[codeArray[1]] +
                        "/" +
                        CodeToText[codeArray[2]];
                    break;
                default:
                    break;
            }
            console.log(area)
            this.area = area
            return area;
        },
        async put() {
            if(!this.$store.getters.getUser){
                this.notifyError('请先登录');
                return false
            }
            if (this.name == '' || this.phone =='' || this.area =='' || this.goods =='') {
                this.notifyError('请填写完整信息');
                return false
            }
           
            let data = {
                "Intro": this.goods,
                "Name": this.name,
                "Mobile": this.phone,
                "Address": this.area,
            }
            let res = await this.postdata("/Shop/SumbitShop", data)
            if (res.code == 200) {
                this.notifySucceed("提交申请成功,等待平台处理");
            }else{
                this.notifyError(res.msg);
            }
        }
    }
}
</script>
<style>
.center {
    width: 100%;
    margin: 0 auto;
}

.heads {
    width: 100%;
    height: 110px;
    background: #ED5D29;
}

.barr1 {
    width: 100%;
    height: 800px;
    position: relative;
}

.imgs {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.cent {
    position: absolute;
    width: 529px;
    height: 620px;
    background: #FFFFFF;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #707070;
    top: 50%;
    transform: translateY(-50%);
    right: 340px;
    padding: 24px 27px;

}

.titl {
    font-size: 20px;
    color: #333333;
    margin-bottom: 13px;
}

.inp {
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #C2C2C2;
    padding: 0 24px;
    font-size: 22px;
    box-sizing: border-box;
    margin-bottom: 12px;
}

.inps {
    width: 100%;
    height: 60px;
    margin-bottom: 12px;
}

.el-input__inner,
.el-input {
    height: 100%;
    font-size: 22px;
    color: #000;
}

.but {
    font-size: 24px;
    color: #FFFFFF;
    width: 477px;
    height: 60px;
    background: #ED5D29;
    border-radius: 4px 4px 4px 4px;
    margin: 45px auto 0;
    cursor: pointer;
}

.tex {
    margin-top: 12px;
    font-size: 18px;
    color: #666666;
}

.yaoqiu {
    width: 1240px;
    margin: 64px auto;
}

.titls {
    margin: 0 auto 36px;
    font-size: 38px;
    color: #000000;
    position: relative;
    text-align: center;
}

.heng {
    width: 67px;
    height: 6px;
    background: #1DA672;
    border-radius: 6px 6px 6px 6px;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
}

.yaoqiu2 {
    width: 245px;
    height: 55px;
    background: #ED5D29;
    border-radius: 10px;
    font-size: 24px;
    color: #FFFFFF;
    margin-top: 45px;
}

.imgs2 {
    width: 33px;
    margin-right: 12px;
    height: 33px;
}

.liucheng {
    width: 100%;
}


.yuanImg {
    width: 70px;
    height: 70px;
    margin-bottom: 23px;
}

.yuan {
    width: 144px;
    font-size: 21px;
    color: #333333;
}

.heng2 {
    width: 227px;
    height: 1px;
    border-bottom: 1px solid #707070;
    margin: 0 15px;
    border-style: dotted;
    margin-bottom: 37px;
    text-align: center;
}
</style>